import createReducer from 'store/createReducer';
import { PersonalFinance, PersonalFinanceActionTypes } from './types';

const initialState: PersonalFinance = {
  salary: null,
  saving: null,
  otherInsurance: null,
  otherPayments: null,
};

export default createReducer(initialState, {
  [PersonalFinanceActionTypes.PERSONAL_FINANCE_UPDATE]: (state: PersonalFinance, { field, value }): PersonalFinance => {
    return { ...state, [field]: value };
  },
});
