/* eslint-disable import/prefer-default-export */

export interface SjekkpunktIdsRead {
  [id: string]: boolean;
}

export interface Read {
  pending: boolean;
  error: Error;
  hasFetchedReadsForTilstandsrapportId: string[];
  readSjekkpunkter: SjekkpunktIdsRead;
}

export enum ReadActionTypes {
  FETCH_READS_REQUEST = '@@user/read/FETCH_REQUEST',
  FETCH_READS_FAILURE = '@@user/read/FETCH_FAILURE',
  FETCH_READS_SUCCESS = '@@user/read/FETCH_SUCCESS',
  SET_SJEKKPUNKT_READ = '@@user/read/MARK_AS_READ',
}

interface FetchReadsRequest {
  type: typeof ReadActionTypes.FETCH_READS_REQUEST;
}

interface FetchReadsFailure {
  type: typeof ReadActionTypes.FETCH_READS_FAILURE;
  error: Error;
}

interface FetchReadsSuccess {
  type: typeof ReadActionTypes.FETCH_READS_SUCCESS;
  tilstandsrapportId: string;
  sjekkpunktIds: SjekkpunktIdsRead;
}

interface SetSjekkpunktRead {
  type: typeof ReadActionTypes.SET_SJEKKPUNKT_READ;
  id: string;
}

export type ReadActionCreators = FetchReadsRequest | FetchReadsFailure | FetchReadsSuccess | SetSjekkpunktRead;
