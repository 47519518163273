import createReducer from 'store/createReducer';
import { ChatActionTypes, ChatState } from './types';

const initialState: ChatState = {
  data: null,
  pending: false,
  error: null,
};

export default createReducer(initialState, {
  [ChatActionTypes.REQUEST]: (state: ChatState): ChatState => {
    return { ...state, pending: true, error: null };
  },
  [ChatActionTypes.FAILURE]: (state: ChatState, { error }): ChatState => {
    return { ...state, pending: false, error };
  },
  [ChatActionTypes.SUCCESS]: (state: ChatState, { data }): ChatState => {
    return { ...state, pending: false, error: null, data };
  },
  [ChatActionTypes.ADD_QA_SUCCESS]: (state: ChatState, { qa }): ChatState => {
    return { ...state, pending: false, error: null, data: {
      ...state.data,
      qas: [
        ...state.data.qas,
        qa
      ]
    } };
  },
  [ChatActionTypes.ADD_QA_FAILURE]: (state: ChatState, { error }): ChatState => {
    return { ...state, pending: false, error };
  },
});
