/* eslint-disable @typescript-eslint/explicit-function-return-type */

const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    // Alternative to handlers.hasOwnProperty(action.type) that gives no ESLint error
    // https://eslint.org/docs/rules/no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

export default createReducer;
