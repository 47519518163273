/* eslint-disable import/prefer-default-export */

import { Matrikkel } from 'store/commonTypes';

export interface LoanInformation {
  amount: number;
  interest: number;
  length: number;
  paymentCost: number;
}

export interface TilstandsrapportUserData {
  loanInformation: LoanInformation;
}

interface Eiendom {
  id: string;
  kildesystem: string;
  kildesystemRefId: string;
  meglerkjedeId: string;
  typeEiendom: string;
  prom: number;
  fellesutgifter: number;
  kommunaleAvgifter: number;
  bilder?: { url?: string; beskrivelse?: string }[];
  matrikkel: Matrikkel;
  kontorId?: string;
  kontorNavn?: string;
}

interface MatrikkelWithBra extends Matrikkel {
  bra: number;
}

type TilstandsrapportKildesystem = 'ETIQ' | 'WEBTAKST' | 'PROTAKST' | 'IVIT' | 'SUPERTAKST' | 'TAKSTSKYEN';

export interface Bokostnader {
  stroemforbruk: number;
  innboforsikring: number;
  husforsikring: number;
  vedlikeholdskostnader: number;
  internett: number;
  tv: number;
  nrk: number;
  alarm: number;
}

export interface Tilstandsrapport {
  objectId: string;
  id: string;
  kildesystem: TilstandsrapportKildesystem;
  kildesystemId: string;
  befaringsDato: Date;
  matrikkelId: MatrikkelWithBra;
  type: string;
  byggear: number;
  bra: number;
  sjekkpunkter: number[];
  createdAt: Date;
  updatedAt: Date;
  callToActionBad: boolean;
  bokostnader: Bokostnader;
  meglerOppdrag: {};
  healthScore: number;
  eiendom?: Eiendom;
  userData?: TilstandsrapportUserData;
}

export enum TilstandsrapportActionTypes {
  UPDATE_LOAN_INFORMATION = '@@tilstandsrapportEntities/tilstandsrapport/UPDATE_LOAN_INFORMATION',
}

interface UpdateLoanInformation {
  type: typeof TilstandsrapportActionTypes.UPDATE_LOAN_INFORMATION;
  tilstandsrapportId: string;
  field: string;
  value: number;
}

export type TilstandsrapportActionCreators = UpdateLoanInformation;
