import getConfig from 'next/config';
import Head from 'next/head';
import { Router, withRouter } from 'next/router';
import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { AppState } from 'store';
import { getActiveTilstandsRapport } from 'store/tilstandsrapportEntities/selectors';
import {
  getFullyQualifiedKategori,
  getSubCategory,
  getTgValue,
} from 'store/tilstandsrapportEntities/sjekkpunkter/selectors';
import { getAddress, makeGetImages, makeGetTgCounts } from 'store/tilstandsrapportEntities/tilstandsrapport/selectors';

interface Props {
  router: Router;
}

interface StateProps {
  title?: string;
  description?: string;
  image?: string;
  urlAffix?: string;
}

const Meta = ({ title, description, urlAffix, image }: Props & StateProps): JSX.Element => {
  const {
    publicRuntimeConfig: { sbaUrl },
  } = getConfig();

  const fullUrl = urlAffix ? sbaUrl + urlAffix : sbaUrl;

  return (
    <Head>
      <meta name="Description" content={description} />
      <meta name="og:description" property="og:description" content={description} />
      <meta name="og:title" property="og:title" content={title} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:domain" content={fullUrl} />
    </Head>
  );
};

Meta.defaultProps = {
  title: 'Vendu Smart Boliganalyse',
  description: 'Vendu Smart Boliganalyse - hjelper deg å forstå boligens tilstand',
  image:
    'https://external.webmegler.no/wngetbilde.ashx?/abilde/k195/f%5Cbilder%5C2018%5C17%5C11%5C3994411_1-vccaeb.jpg',
  urlAffix: null,
};

const makeMapStateToProps = (): MapStateToProps<StateProps, Props, AppState> => {
  const getTgCounts = makeGetTgCounts();
  const getImages = makeGetImages();
  return (state: AppState, { router }: Props): StateProps => {
    // Route corresponds to files in pages directory.
    const { route, asPath } = router;
    const tilstandsrapportId = (router.query && String(router.query.tilstandsrapportId)) || null;
    const itemId = (router.query && String(router.query.itemId)) || null;

    const tilstandsrapport = getActiveTilstandsRapport(state);
    if (!tilstandsrapport) return {};
    switch (route) {
      case '/list':
      case '/overview': {
        const address = getAddress(state, tilstandsrapportId);
        const tgCount = getTgCounts(state, tilstandsrapportId);
        const numRemarks = tgCount.tg2 + tgCount.tg3;
        const totalNum = tgCount.tg1 + tgCount.tg2 + tgCount.tg3;
        return {
          title: `${address}: ${numRemarks} avvik av ${totalNum} kontrollerte bygningsdeler`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          image: getImages(state, tilstandsrapportId)?.[0]?.large,
          urlAffix: asPath,
        };
      }

      case '/item': {
        const address = getAddress(state, tilstandsrapportId);
        const kategori = getFullyQualifiedKategori(state, itemId);
        const underkategori = getSubCategory(state, itemId, tilstandsrapportId);
        const tgValue = getTgValue(state, itemId);
        return {
          title: `${address}: ${kategori} - ${underkategori}, TG${tgValue}`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          image: getImages(state, tilstandsrapportId)?.[0]?.large,
          urlAffix: asPath,
        };
      }

      default: {
        return {};
      }
    }
  };
};

export default withRouter(
  connect(
    makeMapStateToProps,
    null,
  )(Meta),
);
