import createReducer from 'store/createReducer';
import { SearchActionTypes, SearchState } from './types';

const initialState: SearchState = {
  results: [],
  pending: false,
  error: null,
  lastSearch: '',
};

export default createReducer(initialState, {
  [SearchActionTypes.CLEAR]: (state: SearchState): SearchState => {
    return { ...state, results: [], pending: false, error: null, lastSearch: '' };
  },
  [SearchActionTypes.REQUEST]: (state: SearchState, { lastSearch }): SearchState => {
    return { ...state, pending: true, error: null, lastSearch };
  },
  [SearchActionTypes.FAILURE]: (state: SearchState, { error }): SearchState => {
    return { ...state, pending: false, error };
  },
  [SearchActionTypes.SUCCESS]: (state: SearchState, { results }): SearchState => {
    return { ...state, pending: false, error: null, results };
  },
});
