import createReducer from 'store/createReducer';
import { ReportActionTypes, ReportState } from './types';

const initialState: ReportState = {
  pending: false,
  error: null,
  activeReportId: null,
  compareReportId: null,
};

export default createReducer(initialState, {
  [ReportActionTypes.REQUEST]: (state: ReportState): ReportState => {
    return { ...state, pending: true, error: null };
  },
  [ReportActionTypes.SUCCESS]: (state: ReportState): ReportState => {
    return { ...state, pending: null, error: null };
  },
  [ReportActionTypes.FAILURE]: (state: ReportState, { error }): ReportState => {
    return { ...state, pending: false, error };
  },
  [ReportActionTypes.SET_ACTIVE_REPORT]: (state: ReportState, { tilstandsrapportId }): ReportState => {
    return { ...state, activeReportId: tilstandsrapportId };
  },
  [ReportActionTypes.SET_COMPARE_REPORT]: (state: ReportState, { tilstandsrapportId }): ReportState => {
    return { ...state, compareReportId: tilstandsrapportId };
  },
});
