import getConfig from 'next/config';

const {
  publicRuntimeConfig: { auth },
} = getConfig();

// eslint-disable-next-line import/prefer-default-export
export const AWSResources = {
  Auth: {
    identityPoolId: auth.IDENTITY_POOL_ID,
    region: auth.REGION,
    userPoolId: auth.USER_POOL_ID,
    userPoolWebClientId: auth.USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: auth.OAUTH_DOMAIN,
      redirectSignIn: auth.OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: auth.OAUTH_REDIRECT_SIGN_OUT,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      responseType: 'code',
    },
  },
};
