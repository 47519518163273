import * as Sentry from '@sentry/node';
import { AuthModal, AuthProvider } from '@VenduCompanyDemo/ui';
import Amplify from 'aws-amplify';
import AlertTemplate from 'components/common/Alert';
import CookiesInfo from 'components/common/CookiesInfo';
import Meta from 'components/common/Meta';
import withReduxStore from 'lib/with-redux-store';
import App from 'next/app';
import getConfig from 'next/config';
import { Router } from 'next/router';
import React from 'react';
import { positions, Provider as ReactAlertProvider } from 'react-alert';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { setFirstPageView } from 'store/app/actions';

import { AWSResources } from '../amplify';

Amplify.configure(AWSResources);

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

interface Props {
  reduxStore: Store;
  err: Error;
}

// Fix Next issue where CSS doesn't load on pageChange in development
Router.events.on('routeChangeComplete', () => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const els: NodeListOf<any> = document.querySelectorAll('link[href*="/_next/static/css/styles.chunk.css"]');
    const timestamp = new Date().valueOf();
    els[0].href = `/_next/static/css/styles.chunk.css?v=${timestamp}`;
  }
});

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-extraneous-dependencies, global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

Modal.setAppElement('#__next');

const {
  publicRuntimeConfig: { ga, env },
} = getConfig();
const { GTM_ID, GTM_AUTH, GTM_PREVIEW } = ga;

Sentry.init({
  dsn: 'https://3d5d05390ada402fa1c2abd10924fea6@sentry.io/1804887',
  enabled: process.env.NODE_ENV === 'production',
});

const isInIframe = () => {
  if (!process.browser) return false;
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

class SmartBoliganalyseApp extends App<Props> {
  constructor(props) {
    super(props);
    this.handleRouteChange = this.handleRouteChange.bind(this);
  }

  handleRouteChange(): void {
    const { reduxStore } = this.props;
    reduxStore.dispatch(setFirstPageView(false));
  }

  componentDidMount(): void {
    Router.events.on('routeChangeComplete', this.handleRouteChange);
  }

  componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  render(): JSX.Element {
    const { Component, pageProps, reduxStore } = this.props;
    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    const isNylander = typeof document !== 'undefined' && document.referrer && /.*nylander\.no/.test(document.referrer);
    return (
      <>
        <Provider store={reduxStore}>
          <ReactAlertProvider
            template={AlertTemplate}
            {...options}
          >
            <AuthProvider>
              <AuthModal authBaseURL="/api"/>
              <Component {...modifiedPageProps} />
              {!isInIframe() && <CookiesInfo/>}
              <Meta/>
            </AuthProvider>
          </ReactAlertProvider>
        </Provider>

        <style jsx global>{`
          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-Light.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-Light.woff') format('woff');
            font-weight: 300;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-LightItalic.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-LightItalic.woff') format('woff');
            font-weight: 300;
            font-style: italic;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-Book.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-Book.woff') format('woff');
            font-weight: 400;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-BookItalic.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-BookItalic.woff') format('woff');
            font-weight: 400;
            font-style: italic;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-Medium.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-Medium.woff') format('woff');
            font-weight: 500;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-MediumItalic.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-MediumItalic.woff') format('woff');
            font-weight: 500;
            font-style: italic;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-Demi.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-Demi.woff') format('woff');
            font-weight: 600;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-DemiItalic.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-DemiItalic.woff') format('woff');
            font-weight: 600;
            font-style: italic;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-Bold.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-Bold.woff') format('woff');
            font-weight: 700;
            font-display: fallback;
          }

          @font-face {
            font-family: MaisonNeue;
            src: url('/fonts/Maison/MaisonNeueWEB-BoldItalic.woff2') format('woff2'),
              url('/fonts/Maison/MaisonNeueWEB-BoldItalic.woff') format('woff');
            font-weight: 700;
            font-style: italic;
            font-display: fallback;
          }

          @font-face {
            font-family: Gordita-Medium;
            src: url('/fonts/Gordita/GorditaMedium.otf') format('opentype'),
            url('/fonts/Gordita/GorditaMedium.otf') format('opentype');
            font-weight: 500;
            font-style: normal;
            font-display: fallback;
          }
          @font-face {
            font-family: Gordita-Light;
            src: url('/fonts/Gordita/GorditaLight.otf') format('opentype'),
            url('/fonts/Gordita/GorditaLight.otf') format('opentype');
            font-weight: 300;
            font-style: normal;
            font-display: fallback;
          }

          * {
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          html,
          body {
            background-color: ${isInIframe() && isNylander ? '#3b3c43' : '#fff'};
            font-family: MaisonNeue, Arial, sans-serif;
            font-weight: 300;
            font-size: 16px;
            min-height: 100vh;
            margin: 0;
            padding: 0;
          }
          .ReactModal__Body--open {
            overflow: hidden;
            position: fixed;
            width: 100%;
            height: 100%;
          }
          .ReactModal__Overlay {
            z-index: 10000;
          }
          .ReactCollapse--collapse {
            transition: height 200ms;
          }
        `}</style>
      </>
    );
  }
}

export default withReduxStore(SmartBoliganalyseApp);
