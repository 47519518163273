import React, { RefObject } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import styles from './styles.scss';
import 'assets/scss/slick.scss';

export interface Props {
  modalOpen: boolean;
  header?: string;
  text?: string[];
  onRequestClose: Function;
}

class TermsModal extends React.Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slider: RefObject<any>;

  state = {
    currentSlide: 0,
  };

  constructor(props: Props) {
    super(props);
    this.slider = React.createRef();
  }

  onChange = (index: number): void => {
    this.setState({ currentSlide: index });
  };

  renderTextPages = (text: string[]): JSX.Element => (
    <Slider
      infinite={false}
      swipe={false}
      afterChange={this.onChange}
      className={styles.slider}
      ref={this.slider}
      speed={300}
    >
      {text.map((pageText, i) => (
        <div key={i}>
          <p>{pageText}</p>
        </div>
      ))}
    </Slider>
  );

  handleClose = (): void => {
    this.slider.current.slickGoTo(0);
    this.setState({ currentSlide: 0 });
    this.props.onRequestClose();
  };

  render(): JSX.Element {
    const { header, text, modalOpen } = this.props;
    return (
      <Modal isOpen={modalOpen} className={styles.modal}>
        <h2>{header}</h2>
        {this.renderTextPages(text)}

        <div className={styles.buttonContainer}>
          <button
            onClick={(): void =>
              text.length - 1 === this.state.currentSlide ? this.handleClose() : this.slider.current.slickNext()
            }
            className={styles.button}
          >
            Jeg forstår
          </button>
        </div>
        <div className={styles.pageNumber}>
          {this.state.currentSlide + 1} av {text.length}
        </div>
      </Modal>
    );
  }
}

export const UsageTermsModal = (props: Props): JSX.Element => {
  const infoModalHeader = 'Dette må du vite før bruk';
  const infoModalText = [
    'Smart Boliganalyse presenterer innhold fra Tilstandsrapporten. Tilstandsrapporten er det juridiske dokumentet som beskriver eiendommens teknisk tilstand. Les Tilstandsrapporten og bruk denne tjenesten som et supplement for forstå innholdet bedre.',
    'Vendu har gjort en maskinell utbedringsanalyse som hjelper deg å forstå teknisk tilstand. Det påpekes at informasjonen er veiledende og at denne ikke kan påberopes mot noen parter i bolighandelen.',
    'Som boligkjøper har man selv ansvar for de feil og mangler som er beskrevet i tilstandsrapporten, jf. Avhl. § 3-10 som beskriver kjøpers undersøkelsesplikt.',
  ];
  return (
    <TermsModal
      modalOpen={props.modalOpen}
      onRequestClose={props.onRequestClose}
      header={infoModalHeader}
      text={infoModalText}
    />
  );
};

export const UtbedringTermsModal = (props: Props): JSX.Element => {
  const header = 'Utbedringsanalyse';
  const text = [
    'Innholdet i utbedringsanalysen er basert på erfaringsdata fra takstmenn, med referanser til Sintef Byggforsk og utarbeidet i samarbeid med byggtekniske fagfolk fra Norsk Takst.',
    'Det er viktig å påpeke at informasjonen er veiledende og at denne ikke kan påberopes  mot noen parter i bolighandelen.',
  ];
  return <TermsModal modalOpen={props.modalOpen} onRequestClose={props.onRequestClose} header={header} text={text} />;
};

export default TermsModal;
