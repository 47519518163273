import createReducer from 'store/createReducer';
import { ModalActionTypes, ModalState } from './types';

const initialState: ModalState = {
  open: null,
};

export default createReducer(initialState, {
  [ModalActionTypes.OPEN]: (state: ModalState, { modal }): ModalState => {
    return { ...state, open: modal };
  },
  [ModalActionTypes.CLOSE]: (state: ModalState): ModalState => {
    return { ...state, open: null };
  },
});
