import createReducer from 'store/createReducer';
import { ExamplesActionTypes, ExamplesState } from './types';

const initialState: ExamplesState = {
  data: [],
  pending: false,
  error: null,
};

export default createReducer(initialState, {
  [ExamplesActionTypes.REQUEST]: (state: ExamplesState): ExamplesState => {
    return { ...state, pending: true, error: null };
  },
  [ExamplesActionTypes.FAILURE]: (state: ExamplesState, { error }): ExamplesState => {
    return { ...state, pending: false, error };
  },
  [ExamplesActionTypes.SUCCESS]: (state: ExamplesState, { data }): ExamplesState => {
    return { ...state, pending: false, error: null, data };
  },
});
