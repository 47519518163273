import createReducer from 'store/createReducer';
import { VisitedActionTypes, VisitedState } from './types';

const initialState: VisitedState = {
  data: [],
  pending: false,
  error: null,
};

export default createReducer(initialState, {
  [VisitedActionTypes.REQUEST]: (state: VisitedState): VisitedState => {
    return { ...state, pending: true, error: null };
  },
  [VisitedActionTypes.FAILURE]: (state: VisitedState, { error }): VisitedState => {
    return { ...state, pending: false, error };
  },
  [VisitedActionTypes.SUCCESS]: (state: VisitedState, { data }): VisitedState => {
    return { ...state, pending: false, error: null, data };
  },
});
