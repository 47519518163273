// State

export enum ModalTypes {
  TG_MODAL = 'TG_MODAL',
}

export interface ModalState {
  open: ModalTypes;
}

// Actions
// eslint-disable-next-line import/prefer-default-export
export enum ModalActionTypes {
  OPEN = '@@modals/OPEN',
  CLOSE = '@@modals/CLOSE',
}

// Action Creators
interface ModalOpen {
  type: typeof ModalActionTypes.OPEN;
  modal: ModalTypes;
}

interface ModalClose {
  type: typeof ModalActionTypes.CLOSE;
}

export type ModalActionCreators = ModalOpen | ModalClose;
