/* eslint-disable import/prefer-default-export */

// State
export interface Example {
  id: string;
  adresse: string;
  postnr: string;
  poststed: string;
  bilde: string;
}

export interface ExamplesState {
  readonly data: Example[];
  readonly pending: boolean;
  readonly error: Error;
}

// Actions
export enum ExamplesActionTypes {
  REQUEST = '@@examples/REQUEST',
  SUCCESS = '@@examples/SUCCESS',
  FAILURE = '@@examples/FAILURE',
}

// Action Creators
interface ExamplesRequest {
  type: typeof ExamplesActionTypes.REQUEST;
}

interface ExamplesSuccess {
  type: typeof ExamplesActionTypes.SUCCESS;
  data: [];
}

interface ExamplesFailure {
  type: typeof ExamplesActionTypes.FAILURE;
  error: Error;
}

export type ExamplesActionCreators = ExamplesRequest | ExamplesSuccess | ExamplesFailure;
