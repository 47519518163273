/* eslint-disable import/prefer-default-export */

export interface PersonalFinance {
  salary: number;
  otherPayments: number;
  otherInsurance: number;
  saving: number;
}

// Actions
export enum PersonalFinanceActionTypes {
  PERSONAL_FINANCE_SUCCESS = '@@user/personalFinance/SUCCESS',
  PERSONAL_FINANCE_UPDATE = '@@user/personalFinance/UPDATE',
}

// Action Creators
interface PersonalFinanceSuccess {
  type: typeof PersonalFinanceActionTypes.PERSONAL_FINANCE_SUCCESS;
  personalFinance: null;
}

interface UpdatePersonalFinance {
  type: typeof PersonalFinanceActionTypes.PERSONAL_FINANCE_UPDATE;
  field: string;
  value: number;
}

export type PersonalFinanceActionCreators = PersonalFinanceSuccess | UpdatePersonalFinance;
