import createReducer from 'store/createReducer';
import { FavoritesActionTypes, FavoritesState } from './types';

const initialState: FavoritesState = {
  data: [],
  pending: false,
  error: null,
};

export default createReducer(initialState, {
  [FavoritesActionTypes.REQUEST]: (state: FavoritesState): FavoritesState => {
    return { ...state, pending: true, error: null };
  },
  [FavoritesActionTypes.FAILURE]: (state: FavoritesState, { error }): FavoritesState => {
    return { ...state, pending: false, error };
  },
  [FavoritesActionTypes.SUCCESS]: (state: FavoritesState, { data }): FavoritesState => {
    return { ...state, pending: false, error: null, data };
  },
});
