import { combineReducers } from 'redux';
import createReducer from 'store/createReducer';
import sjekkpunkterReducer from './sjekkpunkter/reducers';
import tilstandsrapportReducer from './tilstandsrapport/reducers';
import { TilstandsrapportEntitiesActionTypes } from './types';

const initialState: number[] = [];

const allIdsReducer = createReducer(initialState, {
  [TilstandsrapportEntitiesActionTypes.ADD]: (state: number[], action): number[] => {
    const {
      data: { result },
    } = action;
    return [...state, result];
  },
});

export default combineReducers({
  allIds: allIdsReducer,
  tilstandsrapport: tilstandsrapportReducer,
  sjekkpunkter: sjekkpunkterReducer,
});
