import React, { useState, useEffect } from 'react';
import styles from './styles.scss';
import { UsageTermsModal } from './TermsModal';

const CookiesInfo = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    if (!(localStorage.getItem('cookies-user-consent') === 'accepted')) {
      setPopupOpen(true);
    }
  }, []);
  const acceptCookies = (): void => {
    setPopupOpen(false);
    localStorage.setItem('cookies-user-consent', 'accepted');
  };
  return (
    <>
      <div className={styles.cookiesPopup} style={{ display: popupOpen ? 'flex' : 'none' }}>
        <p>Vi bruker informasjonskapsler for å gi deg en god brukeropplevelse.</p>
        <button className={styles.readMore} onClick={(): void => setModalOpen(true)}>
          Les mer
        </button>
        <button className={styles.accept} onClick={acceptCookies} data-test-id="acceptCookies">
          Ok
        </button>
      </div>
      <UsageTermsModal modalOpen={modalOpen} onRequestClose={(): void => setModalOpen(false)} />
    </>
  );
};

export default CookiesInfo;
