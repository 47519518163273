import createReducer from 'store/createReducer';
import { TilstandsrapportEntitiesActionTypes } from 'store/tilstandsrapportEntities/types';
import { Sjekkpunkt } from './types';

const initialState: {
  [id: string]: Sjekkpunkt;
} = {};

export default createReducer(initialState, {
  [TilstandsrapportEntitiesActionTypes.ADD]: (state: Sjekkpunkt, { data }): Sjekkpunkt | {} => {
    const {
      entities: { sjekkpunkter = {} },
    } = data;

    // TODO: Rewrite to Object.fromEntries once we upgrade to higher Node version. Check if we need browser polyfill.
    const modified = Object.entries(sjekkpunkter).reduce((acc, [key, sjekkpunkt]: [string, Sjekkpunkt]) => {
      if (sjekkpunkt.tg === null) {
        return { ...acc, [key]: { ...sjekkpunkt, tg: -1 } };
      }
      return { ...acc, [key]: sjekkpunkt };
    }, {});

    return modified ? { ...state, ...modified } : {};
  },
});
