/* eslint-disable import/prefer-default-export */

// State
import { Matrikkel } from 'store/commonTypes';

export interface SearchResult extends Matrikkel {
  id: string;
  bra: number;
}

export interface SearchState {
  readonly results: SearchResult[];
  readonly pending: boolean;
  readonly error: Error;
  readonly lastSearch: string;
}

// Actions
export enum SearchActionTypes {
  CLEAR = '@@search/CLEAR',
  REQUEST = '@@search/REQUEST',
  SUCCESS = '@@search/SUCCESS',
  FAILURE = '@@search/FAILURE',
}

// Action Creators
interface SearchClear {
  type: typeof SearchActionTypes.CLEAR;
}

interface SearchRequest {
  type: typeof SearchActionTypes.REQUEST;
  lastSearch: string;
}

interface SearchSuccess {
  type: typeof SearchActionTypes.SUCCESS;
  results: [];
}

interface SearchFailure {
  type: typeof SearchActionTypes.FAILURE;
  error: Error;
}

export type SearchActionCreators = SearchClear | SearchRequest | SearchSuccess | SearchFailure;
