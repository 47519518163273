import { createStore, applyMiddleware, combineReducers, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import appReducer from './app/reducers';
import chatReducer from './chat/reducers';
import examplesReducer from './example/reducers';
import favoritesReducer from './favorites/reducers';
import modalReducer from './modal/reducers';
import reportReducer from './report/reducers';
import searchReducer from './search/reducers';
import tilstandsrapportEntitiesReducer from './tilstandsrapportEntities/reducers';
import userReducer from './user/reducers';
import visitedReducer from './visited/reducers';

export const rootReducer = combineReducers({
  report: reportReducer,
  search: searchReducer,
  tilstandsrapportEntities: tilstandsrapportEntitiesReducer,
  examples: examplesReducer,
  modal: modalReducer,
  user: userReducer,
  app: appReducer,
  favorites: favoritesReducer,
  visited: visitedReducer,
  chat: chatReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function initializeStore(initialState): Store {
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
}
