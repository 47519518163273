import createReducer from 'store/createReducer';
import { AppActionTypes, AppState } from './types';

const initialState: AppState = {
  firstPageView: true,
};

export default createReducer(initialState, {
  [AppActionTypes.SET_FIRST_PAGEVIEW]: (state: AppState, { firstPageView }): AppState => {
    return { ...state, firstPageView };
  },
});
