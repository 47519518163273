// State
export interface AppState {
  firstPageView: boolean;
}

// Actions
// eslint-disable-next-line import/prefer-default-export
export enum AppActionTypes {
  SET_FIRST_PAGEVIEW = '@@app/SET_FIRST_PAGEVIEW',
}

// Action Creators
interface SetFirstPageView {
  type: typeof AppActionTypes.SET_FIRST_PAGEVIEW;
  firstPageView: boolean;
}

export type AppActionCreators = SetFirstPageView;
