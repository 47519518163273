import createReducer from '../../createReducer';
import { Read, ReadActionTypes } from './types';

const initialState: Read = {
  pending: false,
  error: null,
  hasFetchedReadsForTilstandsrapportId: [],
  readSjekkpunkter: {},
};

export default createReducer(initialState, {
  [ReadActionTypes.FETCH_READS_REQUEST]: (state: Read): Read => {
    return { ...state, pending: true, error: null };
  },
  [ReadActionTypes.FETCH_READS_SUCCESS]: (state: Read, payload): Read => {
    const { tilstandsrapportId, sjekkpunktIds } = payload;
    return {
      ...state,
      pending: false,
      error: null,
      hasFetchedReadsForTilstandsrapportId: [...state.hasFetchedReadsForTilstandsrapportId, tilstandsrapportId],
      readSjekkpunkter: { ...state.readSjekkpunkter, ...sjekkpunktIds },
    };
  },
  [ReadActionTypes.FETCH_READS_FAILURE]: (state: Read, error: Error): Read => {
    return { ...state, pending: false, readSjekkpunkter: state.readSjekkpunkter, error };
  },
  [ReadActionTypes.SET_SJEKKPUNKT_READ]: (state: Read, { id }): Read => {
    return {
      ...state,
      readSjekkpunkter: { ...state.readSjekkpunkter, [id]: true },
    };
  },
});
