import createReducer from 'store/createReducer';
import { TilstandsrapportEntitiesActionTypes } from 'store/tilstandsrapportEntities/types';
import { Tilstandsrapport, TilstandsrapportActionTypes, TilstandsrapportUserData } from './types';

const initialState: {
  [id: string]: Tilstandsrapport;
} = {};

const initialUserData: TilstandsrapportUserData = {
  loanInformation: {
    amount: null,
    interest: null,
    length: null,
    paymentCost: null,
  },
};

// Ensure that userData exist in tilstandsrapport even though it's not sent from server.
// TODO: When we remove userData out of tilstandsrapport, we can remove the ".ADD" reducer in userDataReducer.
const userDataReducer = createReducer(initialUserData, {
  [TilstandsrapportEntitiesActionTypes.ADD]: (state: TilstandsrapportUserData): TilstandsrapportUserData => {
    return { ...state };
  },
  [TilstandsrapportActionTypes.UPDATE_LOAN_INFORMATION]: (
    state: TilstandsrapportUserData,
    action,
  ): TilstandsrapportUserData => {
    const { field, value } = action;
    return { ...state, loanInformation: { ...state.loanInformation, [field]: value } };
  },
});

export default createReducer(initialState, {
  [TilstandsrapportEntitiesActionTypes.ADD]: (state: Tilstandsrapport, action): Tilstandsrapport => {
    const {
      entities: { tilstandsrapport },
      result,
    } = action.data;

    return {
      ...state,
      [result]: { ...tilstandsrapport[result], userData: userDataReducer(tilstandsrapport[result].userData, action) },
    };
  },
  [TilstandsrapportActionTypes.UPDATE_LOAN_INFORMATION]: (state: Tilstandsrapport, action): Tilstandsrapport => {
    const { tilstandsrapportId } = action;
    return {
      ...state,
      [tilstandsrapportId]: {
        ...state[tilstandsrapportId],
        userData: userDataReducer(state[tilstandsrapportId].userData, action),
      },
    };
  },
});
